import { appealUserConstants } from "./constants";

const initialState = {
  isSending: false,
  reOrgInfo: {},
  userExecutPosition: null,
  currentItem: {},
  userAddress: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case appealUserConstants.GetUsersByReOrgId.REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case appealUserConstants.GetUsersByReOrgId.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload.result,
      };
    case appealUserConstants.GetUsersByReOrgId.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };

    case appealUserConstants.GetReOrgInfoByUser.REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case appealUserConstants.GetReOrgInfoByUser.SUCCESS:
      return {
        ...state,
        isSending: false,
        reOrgInfo: action.payload.result,
      };
    case appealUserConstants.GetReOrgInfoByUser.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };

    case appealUserConstants.GetUserExecutPosition.REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case appealUserConstants.GetUserExecutPosition.SUCCESS:
      return {
        ...state,
        isSending: false,
        userExecutPosition: action.payload.result,
      };
    case appealUserConstants.GetUserExecutPosition.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case appealUserConstants.GetAppealUserAddress.REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case appealUserConstants.GetAppealUserAddress.SUCCESS:
      return {
        ...state,
        isSending: false,
        userAddress: action.payload.result,
      };
    case appealUserConstants.GetAppealUserAddress.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case appealUserConstants.GetAppealUserAddress.CLEAR:
      return {
        ...state,
        userAddress: null,
      };

    case appealUserConstants.GetReOrgInfoByReOrgId.REQUEST:
      return {
        ...state,
        isSending: true,
        reOrgInfo: null
      };
    case appealUserConstants.GetReOrgInfoByReOrgId.SUCCESS:
      return {
        ...state,
        isSending: false,
        reOrgInfo: action.payload.result,
      };
    case appealUserConstants.GetReOrgInfoByReOrgId.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      };
    case appealUserConstants.GetReOrgInfoByReOrgId.CLEAR:
      return {
        ...state,
        reOrgInfo: {},
      };

    default:
      return state;
  }
}
