export const accessRightsConstants = {
	createAddition: {
		FAILURE : 'CREATE_ACCESS_ADITION_FAILURE',
		REQUEST : 'CREATE_ACCESS_ADITION_REQUEST',
		SUCCESS : 'CREATE_ACCESS_ADITION_SUCCESS'
	},
	createException: {
		FAILURE : 'CREATE_ACCESS_EXCEPTION_FAILURE',
		REQUEST : 'CREATE_ACCESS_EXCEPTION_REQUEST',
		SUCCESS : 'CREATE_ACCESS_EXCEPTION_SUCCESS'
	},
	createAccessRights: {
		FAILURE : 'CREATE_ACCESS_RIGHTS_ASSIGNMENT_FAILURE',
		REQUEST : 'CREATE_ACCESS_RIGHTS_ASSIGNMENT_REQUEST',
		SUCCESS : 'CREATE_ACCESS_RIGHTS_ASSIGNMENT_SUCCESS'
	},
	deleteAddition: {
		FAILURE : 'DELETE_ACCESS_ADITION_FAILURE',
		REQUEST : 'DELETE_ACCESS_ADITION_REQUEST',
		SUCCESS : 'DELETE_ACCESS_ADITION_SUCCESS'
	},
	deleteException: {
		FAILURE : 'DELETE_ACCESS_EXCEPTION_FAILURE',
		REQUEST : 'DELETE_ACCESS_EXCEPTION_REQUEST',
		SUCCESS : 'DELETE_ACCESS_EXCEPTION_SUCCESS'
	},
   	getOrgsAndRightsPaged: {
		FAILURE : 'GET_REORGS_WITH_RIGHTS_PAGED_FAILURE',
		REQUEST : 'GET_REORGS_WITH_RIGHTS_PAGED_REQUEST',
		SUCCESS : 'GET_REORGS_WITH_RIGHTS_PAGED_SUCCESS'
	},
   	getAdditionsListPaged: {
		FAILURE : 'GET_ADDITIONS_LIST_PAGED_FAILURE',
		REQUEST : 'GET_ADDITIONS_LIST_PAGED_REQUEST',
		SUCCESS : 'GET_ADDITIONS_LIST_PAGED_SUCCESS'
	},
	getExceptionsListPaged: {
		FAILURE : 'GET_EXCEPTIONS_LIST_PAGED_FAILURE',
		REQUEST : 'GET_EXCEPTIONS_LIST_PAGED_REQUEST',
		SUCCESS : 'GET_EXCEPTIONS_LIST_PAGED_SUCCESS'
	},
	getReGosControlTypeList: {
		FAILURE : 'GET_CONTROL_TYPE_LIST_FAILURE',
		REQUEST : 'GET_CONTROL_TYPE_LIST_REQUEST',
		SUCCESS : 'GET_CONTROL_TYPE_LIST_SUCCESS'
	},
	getReGosCategoryList: {
		FAILURE : 'GET_CATEGORY_LIST_FAILURE',
		REQUEST : 'GET_CATEGORY_LIST_REQUEST',
		SUCCESS : 'GET_CATEGORY_LIST_SUCCESS'
	},
	getReGosTerritorialActivityList: {
		FAILURE : 'GET_TERRITORIAL_ACTIVITY_LIST_FAILURE',
		REQUEST : 'GET_TERRITORIAL_ACTIVITY_LIST_REQUEST',
		SUCCESS : 'GET_TERRITORIAL_ACTIVITY_LIST_SUCCESS'
	},
	clearAssignments: 'CLEAR_ACCESS_RIGHTS_ASSIGNMENTS'
}

