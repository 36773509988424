export const applicantNotificationsConstants = {
	downloadNotificationFile: {
		REQUEST : "DOWNLOAD_NOTIFICATION_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_NOTIFICATION_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_NOTIFICATION_FILE_FAILURE"
	},
	getCountNotRead: {
		REQUEST : "GET_COUNT_NOT_READ_REQUEST",
		SUCCESS : "GET_COUNT_NOT_READ_SUCCESS",
		FAILURE : "GET_COUNT_NOT_READ_FAILURE"
	},
	getFirstNotifications: {
		REQUEST : "GET_FIRST_NOTIFICATIONS_REQUEST",
		SUCCESS : "GET_FIRST_NOTIFICATIONS_SUCCESS",
		FAILURE : "GET_FIRST_NOTIFICATIONS_FAILURE"
	},
	getPagedNotifications: {
		REQUEST : "GET_PAGED_NOTIFICATIONS_REQUEST",
		SUCCESS : "GET_PAGED_NOTIFICATIONS_SUCCESS",
		FAILURE : "GET_PAGED_NOTIFICATIONS_FAILURE",
		CLEAR   : "CLEAR_NOTIFICATIONS"
	},
	readAllNotifications: {
		REQUEST : "READ_ALL_NOTIFICATIONS_REQUEST",
		SUCCESS : "READ_ALL_NOTIFICATIONS_SUCCESS",
		FAILURE : "READ_ALL_NOTIFICATIONS_FAILURE"
	}
}
