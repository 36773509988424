import {
	appealsConstants
}from "./constants"
import {
	resolutionsConstants
}from "../Resolutions/store/constants"
import {
	userConstants
}from "../../../store/constants"

const initialState = {
	fileUploadChange                                : null,
	filesInfo                                       : null,
	changed                                         : null,
	answerFiles                                     : null,
	appeal                                          : null,
	appealChangeHistory                             : [],
	appealDownloadFile                              : null,
	appealFiles                                     : null,
	appealLoading                                   : null,
	appealRequestFormFile                           : null,
	appealRequestForms                              : [],
	appealRequestFormsPaged                         : null,
	checkedPrevAppeals                              : null,
	checkedPrevAppealsSending                       : false,
	countRows                                       : 0,
	createRequestForm                               : false,
	createResponseForm                              : false,
	createResult                                    : null,
	createResultForm                                : false,
	data                                            : [],
	editReviewProcessError                          : null,
	editReviewProcessLoading                        : false,
	error                                           : null,
	executorId                                      : null,
	filters                                         : null,
	finaliseAppealError                             : null,
	finaliseAppealLoading                           : false,
	getAnswerFilesByAppealId                        : false,
	getListPrevAppealsForDisciplinaryActionsError   : null,
	getListPrevAppealsForDisciplinaryActionsLoading : false,
	headViceOrg                                     : [],
	isAppealDownloadFile                            : false,
	isAppealRequestFormFileLoading                  : false,
	isAppealRequestFormsLoading                     : false,
	isAppealRequestFormsPagedLoading                : false,
	isExcelFileLoading                              : null,
	isLoading                                       : false,
	isRedirectAppealFormFileLoading                 : false,
	isSending                                       : false,
	isTransmittalFileLoading                        : false,
	listAppeal                                      : null,
	listPrevAppealsForDisciplinaryActions           : null,
	listRa                                          : null,
	listRedirectRas                                 : [],
	minReOrgAppealsInfo                             : null,
	prevAppeals                                     : [],
	reAppealViewTypeList                            : null,
	reOrgAppeal                                     : null,
	redirectAppealFormFile                          : null,
	redirectAppealForms                             : [],
	redirectAppealFormsLoading                      : false,
	repeatAppealsList                               : [],
	transmittalFile                                 : null,
	finaliseResult                                  : null,
	resHistoryVisible                               : true,
	trHistoryVisible                                : true,
	isSetFilters                                    : null,
	backOptions                                     : null,
	openFilters                                     : false,
	filterAddressObj                                : { isOpenAddressForm: false },
	redirectChanged                                 : null,
	redirectResult                                  : null,
	isRedirected                                    : false,
	WCResult										: null,
	wasRecalled									    : null,

	takeIntoAccountLoading : false,
	takeIntoAccountResult  : null,

	recallByRegistratorFiles  : null,
	recallByRegistratorResult : null,
	recallByRegistratorLoading : false,
	recallBasisFile           : null,
	convertFile               : null,
	isGeneratedFileLoading    : false,
	accompanyingLetterFile    : null,
	potentialOrgAppealTerritories : null,
	potentialOrgAppealTerritoriesLoading : false,
	reOrgAppealTerritoriesList : null,
	reOrgAppealTerritoriesListLoading : false,
	isDownloadingAOMFile      : null,
	appealNoticeChanged 	  : null,
	generateTransmittalLetterFileLoading : false
}

function checkOpenAddressForm(action){
	if (!action.payload)
		return false

	const filterAddressObj = {
		isOpenAddressForm: false
	} //for initial applicant search then isOpenAddressForm to false

	for (const property in action.payload)
		filterAddressObj[property] = action.payload[property]

	return filterAddressObj
}

export default function (state = initialState, action){
	switch (action.type){
		case userConstants.Logout.SUCCESS:
			return {
				...state,
				openFilters      : false,
				filters          : null,
				filterAddressObj : {}
			}
		case appealsConstants.CLEAR_ADDRESS_SEARCH_FIELD:{
			const name = action.payload
			const { isOpenAddressForm, ...otherFilterProps } = state.filterAddressObj
			const { [name]: deleteField, ...newFilterObj } = otherFilterProps

			let needToFalseIsOpenAddressForm = false
			for (const property in newFilterObj){
				if (!newFilterObj[property]){
					needToFalseIsOpenAddressForm = true
					break
				}
			}
			return {
				...state,
				filterAddressObj: {
					...newFilterObj,
					isOpenAddressForm: needToFalseIsOpenAddressForm ? false : state.filterAddressObj.isOpenAddressForm
				}
			}
		}
		case appealsConstants.SET_FILTER:{
			const { name, value, sliceSelects } = action.payload
			const resetFilterAddressObj = sliceSelects?.reduce((accum, item) => {
				if (state.filterAddressObj[item]){
					return {
						...accum,
						[item]: null
					}
				}

				return {
					...accum
				}
			}, {})
			const finalFilterObject = resetFilterAddressObj && Object.keys(resetFilterAddressObj).length > 0
				? {
					...state.filterAddressObj,
					...resetFilterAddressObj
				}
				: state.filterAddressObj
			return {
				...state,
				filterAddressObj: {
					...finalFilterObject,
					[name]: value
				}
			}
		}
		case appealsConstants.SET_TO_CLOSE_ADDRESS_FORM:{
			if (state.filterAddressObj.isOpenAddressForm){
				return {
					...state,
					filterAddressObj: {
						...state.filterAddressObj,
						isOpenAddressForm: false
					}
				}
			}

			return state
		}
		case appealsConstants.SET_FILTERS:{
			const isCloseFilters = action.payload

			/* const filterAddressObj = checkOpenAddressForm(action)
			   const clearIsOpenAddressForm = action.payload?.clearIsOpenAddressForm */
			return {
				...state,
				openFilters          : isCloseFilters,
				isChangeFilterFields : true,
				filterAddressObj     : !isCloseFilters ? {} : state.filterAddressObj,
				oldFilterAddressObj  : !isCloseFilters ? null : {
					...state.filterAddressObj
				},
				isSetFilters : isCloseFilters && isCloseFilters.clear ? state.isSetFilters : {}, //for rerender table
				filters      : { //проверить поле куда подано в списке поданных вами, а также адрес
					...state.filters,
					filters: action.payload ?
						{
							...state.filters.filters,
							...action.payload,
							wasClear: false
						} :
						{
							...action.payload,
							wasClear: true
						}
				}
			}
		}
		case appealsConstants.SET_BACK_URL:{
			const openAddressForm = Object.keys(state.isChangeFilterFields ? state.filterAddressObj : state.oldFilterAddressObj ?? {}).length > 1
			return {
				...state,
				backOptions          : action.payload,
				openFilters          : state.isChangeFilterFields || state.oldFilterAddressObj ? state.openFilters : false,
				isChangeFilterFields : false,
				filterAddressObj     : state.isChangeFilterFields ? {
					...state.filterAddressObj,
					isOpenAddressForm: openAddressForm
				} : {
					...state.oldFilterAddressObj,
					isOpenAddressForm: openAddressForm
				}
			}
		}
		case appealsConstants.SET_RH:{
			return {
				...state,
				resHistoryVisible: !state.resHistoryVisible
			}
		}
		case appealsConstants.SET_TH:{
			return {
				...state,
				trHistoryVisible: !state.trHistoryVisible
			}
		}
		case resolutionsConstants.CreateResolution.SUCCESS:{
			return {
				...state,
				reOrgAppeal: {
					...state.reOrgAppeal,
					status: {
						...state.reOrgAppeal.status,
						code: "12"
					}
				}
			}
		}
		case appealsConstants.GetRedirectedFilesInfo.REQUEST:{
			return {
				...state,
				isSending        : true,
				fileUploadChange : false,
				filesInfo        : null
			}
		}
		case appealsConstants.GetRedirectedFilesInfo.SUCCESS:{
			return {
				...state,
				isSending        : false,
				fileUploadChange : true,
				filesInfo        : action.payload.result
			}
		}
		case appealsConstants.GetRedirectedFilesInfo.FAILURE:{
			return {
				...state,
				isSending        : false,
				fileUploadChange : false,
				filesInfo        : null
			}
		}
		case appealsConstants.RedirectAppeal.REQUEST:{
			return {
				...state,
				redirectResult   : null,
				idRAppeal        : action.payload.id,
				isParentRedirect : action.payload.isParentRedirect,
				isPartlyRedirect : action.payload.isPartlyRedirect,
				isSending        : true
			}
		}
		case appealsConstants.RedirectAppeal.SUCCESS:{
			const roa = {
				...state.reOrgAppeal,
				status: {
					...state.reOrgAppeal.status,
					code: !state.isPartlyRedirect
						? !state.isParentRedirect
							? "16"
							: "18"
						: state.reOrgAppeal.status.code,
					id: !state.isPartlyRedirect
						? !state.isParentRedirect
							? 16
							: 18
						: state.reOrgAppeal.status.id
				}
			}
			return {
				...state,
				redirectResult : action.payload,
				idWCAppeal     : null,
				isSending      : false,
				reOrgAppeal    : {
					...roa
				},
				redirectChanged: true
			}
		}
		case appealsConstants.RedirectAppeal.FAILURE:
			return {
				...state,
				error          : action.payload,
				idRAppeal      : null,
				isSending      : false,
				redirectResult : false
			}
		case appealsConstants.RedirectAppeal.CLEAR:
			return {
				...state,
				createResult     : null,
				createResultForm : null,
				redirectResult   : null
			}
		case appealsConstants.WCAppeal.CLEAR:
			return {
				...state,
				changed: false,
				WCResult     : null
			}
		case appealsConstants.WCAppeal.REQUEST:
			return {
				...state,
				changed      : false,
				WCResult     : null,
				idWCAppeal   : action.payload.id,
				isSending    : true
			}
		case appealsConstants.WCAppeal.SUCCESS:
			return {
				...state,
				changed      : true,
				WCResult 	 : action.payload.result,
				idWCAppeal   : null,
				isSending    : false
			}
		case appealsConstants.WCAppeal.FAILURE:
			return {
				...state,
				error      : action.payload,
				idWCAppeal : null,
				isSending  : false
			}
		case appealsConstants.RecallAppeal.REQUEST:
			return {
				...state,
				idRecallAppeal : action.payload.id,
				isSending      : true,
				wasRecalled    : null
			}
		case appealsConstants.RecallAppeal.SUCCESS:
			return {
				...state,
				isSending : false,
				wasRecalled    : true
			}
		case appealsConstants.RecallAppeal.FAILURE:
			return {
				...state,
				error     : action.payload,
				isSending : false,
				wasRecalled    : false
			}
		case appealsConstants.RecallAppeal.CLEAR:
			return {
				...state,
				wasRecalled    : null
			}
		case appealsConstants.CreateAppeal.REQUEST:
			return {
				...state,
				isSending: true
			}
		case appealsConstants.CreateAppeal.SUCCESS:
			return {
				...state,
				createResult : action.payload.result,
				isSending    : false
			}
		case appealsConstants.CreateAppeal.FAILURE:
			return {
				...state,
				createResult : action.payload,
				error        : action.payload,
				isSending    : false
			}
		case appealsConstants.RegAppeal.REQUEST:
			return {
				...state,
				isSending: true
			}
		case appealsConstants.RegAppeal.SUCCESS:{
			return {
				...state,
				isSending: false,

				//reOrgAppeal : null,
				regResult: action.payload.result
			}
		}
		case appealsConstants.RegAppeal.FAILURE:
			return {
				...state,
				error       : action.payload,
				isSending   : false,
				reOrgAppeal : null
			}
		case appealsConstants.SET_DEFAULT_PAGE:{
			return {
				...state,
				filters: state.filters
					? {
						...state.filters,
						pagination: {
							...state.filters.pagination,
							current : 1,
							page    : 1
						}
					}
					: null
			}
		}
		case appealsConstants.getListRA.CLEAR:{
			return {
				...state,
				listRa: null
			}
		}
		case appealsConstants.getListRA.REQUEST:{
			return {
				...state,
				filters: {
					...action.payload
				},
				isSending : true,
				listRa    : []
			}
		}
		case appealsConstants.getListRA.SUCCESS:{
			const listRa = action.payload.result.listReOrgAppeal.items.map((next) => {
				const { appeal, ...other } = next
				return {
						...other,
						...next.appeal,
						appealId          : next.appeal.id,
						applicantFullName : {
							applicantType : next.appeal.applicantType,
							reOrg         : next.appeal.orgCreator,
							text          : `${ next.appeal.applicantLastName != null ? (next.appeal.applicantLastName + ' ') : ''
							}${ next.appeal.applicantFirstName != null ? (next.appeal.applicantFirstName + ' ') : ''
							}${ next.appeal.applicantMiddleName != null ? next.appeal.applicantMiddleName : '' }`
						},
						endDueDate      : next.endDueDate,
						executionResult : next.executionResult,
						endSignerInfo   : next.endSignerInfo,
						endSignDate     : next.endSignDate,
						id              : next.id,
						key             : next.id,
						regDate         : {
							registerDate    : next.registerDate,
							registerDueDate : next.registerDueDate
						},
						regNumberSED : next.regNumber,
						statusCodes     : next.status.code,
						reAppealType : next.appeal.reAppealType
					}
			
			})

			// const list = action.payload.result.listReOrgAppeal.items.reduce((accum, next) => {
			// 	const { appeal, ...other } = next
			// 	return [
			// 		...accum,
			// 		{
			// 			...other,
			// 			...next.appeal,
			// 			appealId          : next.appeal.id,
			// 			applicantFullName : {
			// 				applicantType : next.appeal.applicantType,
			// 				reOrg         : next.appeal.orgCreator,
			// 				text          : `${ next.appeal.applicantLastName
			// 				} ${ next.appeal.applicantFirstName
			// 				} ${ next.appeal.applicantMiddleName != null ? next.appeal.applicantMiddleName : '' }`
			// 			},
			// 			endDueDate      : next.endDueDate,
			// 			executionResult : next.executionResult,
			// 			endSignerInfo   : next.endSignerInfo,
			// 			endSignDate     : next.endSignDate,
			// 			id              : next.id,
			// 			key             : next.id,
			// 			reAppealType    : next.appeal.reAppealTypeId,
			// 			regDate         : {
			// 				registerDate    : next.registerDate,
			// 				registerDueDate : next.registerDueDate
			// 			},
			// 			regNumberSED : next.regNumber,
			// 			statusCodes     : next.status.code,
			// 			reAppealType : 



			return {
				...state,
				countRows            : action.payload.result.listReOrgAppeal.count,
				isSending            : false,
				listRa               : listRa,
				reAppealTypeList     : action.payload.result.listReAppealType,
				reAppealViewTypeList : action.payload.result.listReAppealViewType,
				reFormTypeList       : action.payload.result.listReFormType,
				reRequestStatusList  : action.payload.result.listReRequestStatus
			}
			
		}
		case appealsConstants.getListRA.FAILURE:
			return {
				...state,
				error     : action.payload,
				isSending : false
			}
		case appealsConstants.GetRedirectRAs.REQUEST:{
			return {
				...state,
				isSending       : true,
				listRedirectRas : []
			}
		}
		case appealsConstants.GetRedirectRAs.SUCCESS:{
			return {
				...state,
				countRows       : action.payload.result.count,
				isSending       : false,
				listRedirectRas : action.payload.result.items
			}
		}
		case appealsConstants.GetRedirectRAs.FAILURE:
			return {
				...state,
				error           : action.payload,
				isSending       : false,
				listRedirectRas : []
			}
		case appealsConstants.ClearCreateResult.CLEAR:
			return {
				...state,
				createResult     : null,
				createResultForm : null,
				reOrgAppeal      : null,
				regResult        : false
			}
		case appealsConstants.GetListAppeal.REQUEST:{
			return {
				...state,
				isSending: true
			}
		}
		case appealsConstants.GetListAppeal.SUCCESS:
			return {
				...state,
				isSending  : false,
				listAppeal : action.payload.result
			}
		case appealsConstants.GetListAppeal.FAILURE:
			return {
				...state,
				error     : action.payload,
				isSending : false
			}
		case appealsConstants.GetAppealById.REQUEST:{
			return {
				...state,
				appealLoading: true
			}
		}
		case appealsConstants.GetAppealById.SUCCESS:
			return {
				...state,
				appeal        : action.payload.result,
				appealLoading : false
			}
		case appealsConstants.GetAppealById.FAILURE:{
			return {
				...state,
				appealLoading : false,
				error         : action.payload
			}
		}
		case appealsConstants.GetAppealById.CLEAR:
			return {
				...state,
				appeal: null
			}
		case appealsConstants.getListPrevAppeals.REQUEST:{
			return {
				...state,
				isLoading: true
			}
		}
		case appealsConstants.getListPrevAppeals.SUCCESS:{
			const arrayWithExludedOriginAppeal = action.payload.result.listReOrgAppeal.items.filter((x) => x.id !== state.reOrgAppeal.id)

			return {
				...state,
				isLoading   : false,
				prevAppeals : arrayWithExludedOriginAppeal
			}
		}
		case appealsConstants.getListPrevAppeals.FAILURE:
			return {
				...state,
				error     : action.payload,
				isLoading : false
			}
		case appealsConstants.GetReOrgAppealById.REQUEST:
		{
			return {
				...state,
				appealLoading   : true,
				redirectChanged : false,
				WCResult : null
			}
		}
		case appealsConstants.GetReOrgAppealById.SUCCESS:
		{
			let obj = {}
			if (action.payload.result.reOrgAppeal){
				obj = {
					reAppealTypeList     : action.payload.result.listReAppealType,
					reAppealViewTypeList : action.payload.result.listReAppealViewType,
					reFormTypeList       : action.payload.result.listReFormType,
					reOrgAppeal          : {
						...action.payload.result.reOrgAppeal,
						listRepetitiveReOrgAppeal: action.payload.result.reOrgAppeal.listRepetitiveReOrgAppeal
							? action.payload.result.reOrgAppeal.listRepetitiveReOrgAppeal.reduce((accum, next, index) => {
								return [
									...accum,
									{
										index        : ++index,
										id           : next.prevReOrgAppeal.id,
										endDate      : next.prevReOrgAppeal.endDate,
										registerDate : next.prevReOrgAppeal.registerDate,
										status       : next.prevReOrgAppeal.status,
										regNumber    : next.prevReOrgAppeal.regNumber
									}
								]
							}, []) : []
					},
					reRequestStatusList: action.payload.result.listReRequestStatus
				}
			}
			else {
				const { listReAppealType, listReAppealViewType, listReFormType, listReRequestStatus, ...other } = action.payload.result
				obj = {

					reAppealTypeList     : listReAppealType,
					reAppealViewTypeList : listReAppealViewType,
					reFormTypeList       : listReFormType,
					reOrgAppeal          : {
						...other,
						listRepetitiveReOrgAppeal: other.listRepetitiveReOrgAppeal.reduce((accum, next, index) => {
							return [
								...accum,
								{
									index        : ++index,
									id           : next.prevReOrgAppeal.id,
									endDate      : next.prevReOrgAppeal.endDate,
									registerDate : next.prevReOrgAppeal.registerDate,
									status       : next.prevReOrgAppeal.status,
									regNumber    : next.prevReOrgAppeal.regNumber
								}
							]
						}, [])
					},
					reRequestStatusList: listReRequestStatus
				}
			}
			return {
				...state,
				appealLoading : false,
				error         : null,
				isLoading     : false,
				...obj
			}
		}
		case appealsConstants.GetReOrgAppealById.FAILURE:
		{
			return {
				...state,
				appealLoading : false,
				error         : action.payload
			}
		}

		case appealsConstants.GetReOrgAppealById.CLEAR:{
			return{
				...state,
				reAppealViewTypeList : null,
				reOrgAppeal : null
			}
		}

		case appealsConstants.GetMinReOrgAppealInfoByAppealId.REQUEST:{
			return {
				...state,
				isLoading           : true,
				minReOrgAppealsInfo : null
			}
		}
		case appealsConstants.GetMinReOrgAppealInfoByAppealId.SUCCESS:
			return {
				...state,
				isLoading           : false,
				minReOrgAppealsInfo : action.payload.result
			}
		case appealsConstants.GetMinReOrgAppealInfoByAppealId.FAILURE:
			return {
				...state,
				error     : action.payload,
				isLoading : false
			}
		case appealsConstants.GetMinReOrgAppealInfoByAppealId.Clear:
			return {
				...state,
				minReOrgAppealsInfo: null
			}
		case appealsConstants.GetAppealFilesByAppealId.REQUEST:{
			return {
				...state,
				appealFiles : null,
				isLoading   : true
			}
		}
		case appealsConstants.GetAppealFilesByAppealId.SUCCESS:
			return {
				...state,
				appealFiles : action.payload.result,
				isLoading   : false
			}
		case appealsConstants.GetAppealFilesByAppealId.FAILURE:
			return {
				...state,
				error     : action.payload,
				isLoading : false
			}
		case appealsConstants.GetAppealFilesByAppealId.CLEAR:
			return {
				...state,
				appealDownloadFile   : null,
				appealFiles          : null,
				isAppealDownloadFile : false
			}
		case appealsConstants.DownloadAppealFile.REQUEST:{
			return {
				...state,
				appealDownloadFile : null,
				isLoading          : true
			}
		}
		case appealsConstants.DownloadAppealAnswerFile.SUCCESS:
		case appealsConstants.DownloadAppealFile.SUCCESS:
			return {
				...state,
				appealDownloadFile   : action.payload.result,
				isAppealDownloadFile : action.type == appealsConstants.DownloadAppealAnswerFile.SUCCESS,
				isLoading            : false
			}
		case appealsConstants.DownloadAppealFile.FAILURE:
			return {
				...state,
				appealDownloadFile : null,
				error              : action.payload,
				isLoading          : false
			}
		case appealsConstants.SET_RA_REGFORM:{
			return {
				...state,
				reOrgAppeal: state.listRa.filter((x) => x.id === action.payload)
			}
		}
		case appealsConstants.GetListPrevAppealsForDisciplinaryActions.REQUEST:{
			return {
				...state,
				getListPrevAppealsForDisciplinaryActionsError   : null,
				getListPrevAppealsForDisciplinaryActionsLoading : true,
				listPrevAppealsForDisciplinaryActions           : null
			}
		}
		case appealsConstants.GetListPrevAppealsForDisciplinaryActions.SUCCESS:
			return {
				...state,
				getListPrevAppealsForDisciplinaryActionsLoading : false,
				listPrevAppealsForDisciplinaryActions           : action.payload.result
			}
		case appealsConstants.GetListPrevAppealsForDisciplinaryActions.FAILURE:
			return {
				...state,
				getListPrevAppealsForDisciplinaryActionsError   : action.payload.err.data,
				getListPrevAppealsForDisciplinaryActionsLoading : false
			}
		case appealsConstants.CheckPreviousAppeal.REQUEST:{
			return {
				...state,
				checkedPrevAppealsSending: true
			}
		}
		case appealsConstants.CheckPreviousAppeal.SUCCESS:
			return {
				...state,
				checkedPrevAppeals        : action.payload.result,
				checkedPrevAppealsSending : false
			}
		case appealsConstants.CheckPreviousAppeal.FAILURE:
			return {
				...state,
				checkedPrevAppealsSending : false,
				error                     : action.payload
			}
		case appealsConstants.CheckPreviousAppeal.Clear:
			return {
				...state,
				checkedPrevAppeals: null
			}
		case appealsConstants.CreateOrgAppeal.REQUEST:
			return {
				...state,
				isSending: true
			}
		case appealsConstants.CreateOrgAppeal.SUCCESS:
			return {
				...state,
				createResult : action.payload.result,
				isSending    : false
			}
		case appealsConstants.CreateOrgAppeal.FAILURE:
			return {
				...state,
				createResult : action.payload,
				error        : action.payload,
				isSending    : false
			}
		case appealsConstants.EditOrgAppeal.REQUEST:
		case appealsConstants.EditElectronicOrgAppeal.REQUEST:
			return {
				...state,
				isSending    : true,
				createResult : null
			}
		case appealsConstants.EditOrgAppeal.SUCCESS:
		case appealsConstants.EditElectronicOrgAppeal.SUCCESS:
			return {
				...state,
				createResult : action.payload.result,
				isSending    : false
			}
		case appealsConstants.EditOrgAppeal.FAILURE:
		case appealsConstants.EditElectronicOrgAppeal.FAILURE:
			return {
				...state,
				createResult : action.payload,
				error        : action.payload,
				isSending    : false
			}
		case appealsConstants.EditOrgAppeal.CLEAR:
			return {
				...state,
				createResult: null
			}
		case appealsConstants.FinaliseAppeal.CLEAR:
			return {
				...state,
				finaliseResult: null
			}
		case appealsConstants.FinaliseAppeal.REQUEST:
			return {
				...state,
				finaliseResult        : null,
				finaliseAppealLoading : true,
				idFAppeal             : action.payload.id
			}
		case appealsConstants.FinaliseAppeal.SUCCESS:
			return {
				...state,
				finaliseResult        : action.payload.result,
				finaliseAppealLoading : false,
				listRa                : state.listRa?.map((item, index) => {
					if (item.id !== state.idFAppeal)
						return item

					return {
						...item,
						status: {
							...item.status,
							code : "13",
							id   : 13
						},
						statusId: 13
					}
				})
			}
		case appealsConstants.FinaliseAppeal.FAILURE:
			return {
				...state,
				finaliseResult        : false,
				finaliseAppealError   : action.payload.err.data,
				finaliseAppealLoading : false,
				idFAppeal             : null
			}
		case appealsConstants.EditReviewProcess.REQUEST:
			return {
				...state,
				editReviewProcessError   : action.payload.err.data,
				editReviewProcessLoading : false
			}
		case appealsConstants.GetAnswerFilesByAppealId.REQUEST:
			return {
				...state,
				answerFiles              : null,
				getAnswerFilesByAppealId : true
			}
		case appealsConstants.GetAnswerFilesByAppealId.SUCCESS:
			return {
				...state,
				answerFiles              : action.payload.result,
				getAnswerFilesByAppealId : false
			}
		case appealsConstants.GetAnswerFilesByAppealId.FAILURE:
			return {
				...state,
				getAnswerFilesByAppealId      : false,
				getAnswerFilesByAppealIdError : action.payload.err.data
			}
		case appealsConstants.GetAnswerFilesByAppealId.CLEAR:
			return {
				...state,
				answerFiles              : null,
				appealDownloadFile       : null,
				getAnswerFilesByAppealId : false,
				isAppealDownloadFile     : false
			}
		case appealsConstants.GetAppealChangeHistory.REQUEST:{
			return {
				...state,
				isSending: true
			}
		}
		case appealsConstants.GetAppealChangeHistory.SUCCESS:
			return {
				...state,
				appealChangeHistory : action.payload.result,
				isSending           : false
			}
		case appealsConstants.GetAppealChangeHistory.FAILURE:
			return {
				...state,
				appealChangeHistory : [],
				isSending           : false
			}
		case appealsConstants.DownloadAppealTransferTransmittalFile.REQUEST:
			return {
				...state,
				isTransmittalFileLoading : true,
				transmittalFile          : null
			}
		case appealsConstants.DownloadAppealTransferTransmittalFile.SUCCESS:
			return {
				...state,
				isTransmittalFileLoading : false,
				transmittalFile          : action.payload.result
			}
		case appealsConstants.DownloadAppealTransferTransmittalFile.FAILURE:
			return {
				...state,
				isTransmittalFileLoading : false,
				transmittalFile          : null
			}
		case appealsConstants.GetHeadViceOrg.REQUEST:
			return {
				...state,
				isSending: true
			}
		case appealsConstants.GetHeadViceOrg.SUCCESS:
			return {
				...state,
				headViceOrg : action.payload.result,
				isSending   : false
			}
		case appealsConstants.GetHeadViceOrg.FAILURE:
			return {
				...state,
				isSending: false
			}
		case appealsConstants.CreateRedirectAppealForm.REQUEST:
			return {
				...state,
				createResultForm : false,
				isSending        : true
			}
		case appealsConstants.CreateRedirectAppealForm.SUCCESS:
			return {
				...state,
				createResultForm : action.payload.result,
				isSending        : false
			}
		case appealsConstants.CreateRedirectAppealForm.FAILURE:
			return {
				...state,
				createResultForm : false,
				isSending        : false
			}
		case appealsConstants.CreateAppealRequestForm.REQUEST:
			return {
				...state,
				createRequestForm : false,
				isSending         : true
			}
		case appealsConstants.CreateAppealRequestForm.SUCCESS:
			return {
				...state,
				createRequestForm : action.payload.result,
				isSending         : false
			}
		case appealsConstants.CreateAppealRequestForm.FAILURE:
			return {
				...state,
				createRequestForm : false,
				isSending         : false
			}
		case appealsConstants.CreateAppealRequestForm.CLEAR:
			return {
				...state,
				createRequestForm  : false,
				createResponseForm : false
			}
		case appealsConstants.CreateAppealResponseForm.REQUEST:
			return {
				...state,
				createResponseForm : false,
				isSending          : true
			}
		case appealsConstants.CreateAppealResponseForm.SUCCESS:
			return {
				...state,
				createResponseForm : action.payload.result,
				isSending          : false
			}
		case appealsConstants.CreateAppealResponseForm.FAILURE:
			return {
				...state,
				createResponseForm : false,
				isSending          : false
			}
		case appealsConstants.GetAppealRequestForms.REQUEST:
			return {
				...state,
				appealRequestForms          : [],
				isAppealRequestFormsLoading : true
			}
		case appealsConstants.GetAppealRequestForms.SUCCESS:
			return {
				...state,
				appealRequestForms          : action.payload.result,
				isAppealRequestFormsLoading : false
			}
		case appealsConstants.GetAppealRequestForms.FAILURE:
			return {
				...state,
				isAppealRequestFormsLoading: false
			}
		case appealsConstants.GetAppealRequestFormsPaged.REQUEST:
			return {
				...state,
				appealRequestFormsPaged          : null,
				isAppealRequestFormsPagedLoading : true
			}
		case appealsConstants.GetAppealRequestFormsPaged.SUCCESS:
			return {
				...state,
				appealRequestFormsPaged          : action.payload.result,
				isAppealRequestFormsPagedLoading : false
			}
		case appealsConstants.GetAppealRequestFormsPaged.FAILURE:
			return {
				...state,
				isAppealRequestFormsPagedLoading: false
			}
		case appealsConstants.DownloadAppealRequestFormFile.REQUEST:
			return {
				...state,
				appealRequestFormFile          : null,
				isAppealRequestFormFileLoading : true
			}
		case appealsConstants.DownloadAppealRequestFormFile.SUCCESS:
			return {
				...state,
				appealRequestFormFile          : action.payload.result,
				isAppealRequestFormFileLoading : false
			}
		case appealsConstants.DownloadAppealRequestFormFile.FAILURE:
			return {
				...state,
				appealRequestFormFile          : null,
				isAppealRequestFormFileLoading : false
			}
		case appealsConstants.ChangeRedirectAppealForm.REQUEST:
			return {
				...state,
				isSending: true
			}
		case appealsConstants.ChangeRedirectAppealForm.SUCCESS:
			return {
				...state,
				redirectAppealForms : action.payload.result,
				isSending           : false
			}
		case appealsConstants.ChangeRedirectAppealForm.FAILURE:
			return {
				...state,
				isSending: false
			}
		case appealsConstants.GetRedirectAppealForms.REQUEST:
			return {
				...state,
				redirectAppealForms        : [],
				redirectAppealFormsLoading : true
			}
		case appealsConstants.GetRedirectAppealForms.SUCCESS:
			return {
				...state,
				redirectAppealForms        : action.payload.result,
				redirectAppealFormsLoading : false
			}
		case appealsConstants.GetRedirectAppealForms.FAILURE:
			return {
				...state,
				redirectAppealFormsLoading: false
			}
		case appealsConstants.DownloadRedirectAppealFormFile.REQUEST:
			return {
				...state,
				isRedirectAppealFormFileLoading : true,
				redirectAppealFormFile          : null
			}
		case appealsConstants.DownloadRedirectAppealFormFile.SUCCESS:
			return {
				...state,
				isRedirectAppealFormFileLoading : false,
				redirectAppealFormFile          : action.payload.result
			}
		case appealsConstants.DownloadRedirectAppealFormFile.FAILURE:
			return {
				...state,
				isRedirectAppealFormFileLoading : false,
				redirectAppealFormFile          : null
			}
		case appealsConstants.RedirectAppealFromForm.REQUEST:
			return {
				...state,
				createResult     : null,
				idRAppeal        : action.payload.id,
				isParentRedirect : action.payload.isParentRedirect,
				isPartlyRedirect : action.payload.isPartlyRedirect,
				isSending        : true,
				isRedirected     : false
			}
		case appealsConstants.RedirectAppealFromForm.SUCCESS:{
			return {
				...state,
				createResult        : action.payload.result.redirect,
				redirectAppealForms : action.payload.result.transfers,
				idWCAppeal          : null,
				isSending           : false,
				isRedirected        : true
			}
		}
		case appealsConstants.RedirectAppealFromForm.FAILURE:
			return {
				...state,
				error        : action.payload,
				idRAppeal    : null,
				isSending    : false,
				isRedirected : false
			}
		case appealsConstants.RedirectAppealFromForm.CLEAR:
			return {
				...state,
				idRAppeal    : null,
				isSending    : false,
				isRedirected : false
			}
		case appealsConstants.ExportListToExcel.REQUEST:
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : true
			}
		case appealsConstants.ExportListToExcel.SUCCESS:
			return {
				...state,
				exportExcelFile    : action.payload.result,
				isExcelFileLoading : false
			}
		case appealsConstants.ExportListToExcel.FAILURE:
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : false
			}
		case appealsConstants.ExportListToExcel.СLEAR:
			return {
				...state,
				exportExcelFile    : null,
				isExcelFileLoading : false
			}
		case appealsConstants.GetRepeatAppeals.REQUEST:
			return {
				...state,
				repeatAppealsList: []
			}
		case appealsConstants.GetRepeatAppeals.SUCCESS:
			return {
				...state,
				repeatAppealsList: action.payload.result
			}
		case appealsConstants.GetRepeatAppeals.FAILURE:
			return {
				...state
			}
		case appealsConstants.GetRepeatAppeals.СLEAR:
			return {
				...state,
				repeatAppealsList: []
			}
		case appealsConstants.TakeIntoAccount.REQUEST:
			return {
				...state,
				takeIntoAccountLoading : true,
				takeIntoAccountResult  : null
			}
		case appealsConstants.TakeIntoAccount.SUCCESS:
			return {
				...state,
				takeIntoAccountLoading : false,
				takeIntoAccountResult  : action.payload.result
			}
		case appealsConstants.TakeIntoAccount.FAILURE:
			return {
				...state,
				takeIntoAccountLoading : false,
				takeIntoAccountResult  : null
			}
		case appealsConstants.TakeIntoAccount.CLEAR:
			return {
				...state,
				takeIntoAccountLoading : false,
				takeIntoAccountResult  : null
			}
		case appealsConstants.GetRecallByRegistratorFiles.REQUEST:
			return {
				...state,
				recallByRegistratorFiles: null
			}
		case appealsConstants.GetRecallByRegistratorFiles.SUCCESS:
			return {
				...state,
				recallByRegistratorFiles: action.payload.result
			}
		case appealsConstants.GetRecallByRegistratorFiles.FAILURE:
			return {
				...state
			}
		case appealsConstants.GetRecallByRegistratorFiles.CLEAR:
			return {
				...state,
				recallByRegistratorFiles: null
			}
		case appealsConstants.RecallByRegistrator.REQUEST:
			return {
				...state,
				recallByRegistratorResult: null,
				recallByRegistratorLoading: true,
				error: null
			}
		case appealsConstants.RecallByRegistrator.SUCCESS:
			return {
				...state,
				recallByRegistratorResult: action.payload.result,
				recallByRegistratorLoading: false,
			}
		case appealsConstants.RecallByRegistrator.FAILURE:
			return {
				...state,
				error : action.payload,
				recallByRegistratorLoading: false,
			}
		case appealsConstants.RecallByRegistrator.CLEAR:
			return {
				...state,
				recallByRegistratorResult: null,
				recallByRegistratorLoading: false,
				error : null
			}
		case appealsConstants.DownloadRecallBasisFile.REQUEST:
			return {
				...state,
				recallBasisFile : null,
				isLoading       : true
			}
		case appealsConstants.DownloadRecallBasisFile.SUCCESS:
			return {
				...state,
				recallBasisFile : action.payload.result,
				isLoading       : false
			}
		case appealsConstants.DownloadRecallBasisFile.FAILURE:
			return {
				...state,
				recallBasisFile : null,
				error           : action.payload,
				isLoading       : false
			}
		case appealsConstants.GetReOrgAppealConvertFile.REQUEST:
			return {
				...state,
				convertFile : null,
				isLoading   : true
			}
		case appealsConstants.GetReOrgAppealConvertFile.SUCCESS:
			return {
				...state,
				convertFile : action.payload.result,
				isLoading   : false
			}
		case appealsConstants.GetReOrgAppealConvertFile.FAILURE:
			return {
				...state,
				convertFile : null,
				error       : action.payload,
				isLoading   : false
			}
		case appealsConstants.GetReOrgAppealConvertFile.CLEAR:
			return {
				...state,
				convertFile: null
			}
		case appealsConstants.GetRedirectAccompanyingLetterFile.REQUEST:
			return {
				...state,
				accompanyingLetterFile : null,
				isGeneratedFileLoading : true
			}
		case appealsConstants.GetRedirectAccompanyingLetterFile.FAILURE:
			return {
				...state,
				error                  : action.payload,
				accompanyingLetterFile : null,
				isGeneratedFileLoading : false
			}
		case appealsConstants.GetRedirectAccompanyingLetterFile.SUCCESS:
			return {
				...state,
				accompanyingLetterFile : action.payload.result,
				isGeneratedFileLoading : false
			}
		case appealsConstants.GetRedirectAccompanyingLetterFile.CLEAR:
			return {
				...state,
				accompanyingLetterFile: null
			}
		case appealsConstants.DownloadAllAppealFiles.REQUEST:
			return {
				...state,
				isLoading: true
			}
		case appealsConstants.DownloadAllAppealFiles.FAILURE:
			return {
				...state,
				error: action.payload,
				isLoading: false
			}
		case appealsConstants.DownloadAllAppealFiles.SUCCESS:
			return {
				...state,
				isLoading: false
			}
		case appealsConstants.GetPotentialOrgAppealTerritories.REQUEST:
			return {
				...state,
				potentialOrgAppealTerritories : null,
				potentialOrgAppealTerritoriesLoading : true
			}
		case appealsConstants.GetPotentialOrgAppealTerritories.FAILURE:
			return {
				...state,
				error                  : action.payload,
				potentialOrgAppealTerritories : null,
				potentialOrgAppealTerritoriesLoading : false
			}
		case appealsConstants.GetPotentialOrgAppealTerritories.SUCCESS:
			return {
				...state,
				potentialOrgAppealTerritories : action.payload.result,
				potentialOrgAppealTerritoriesLoading : false
			}
		case appealsConstants.GetPotentialOrgAppealTerritories.CLEAR:
			return {
				...state,
				potentialOrgAppealTerritories : null
			}
		case appealsConstants.GetAllReOrgAppealTerritories.REQUEST:
			return {
				...state,
				reOrgAppealTerritoriesList : null,
				reOrgAppealTerritoriesListLoading : true
			}
		case appealsConstants.GetAllReOrgAppealTerritories.FAILURE:
			return {
				...state,
				error                  : action.payload,
				reOrgAppealTerritoriesList : null,
				reOrgAppealTerritoriesListLoading : false
			}
		case appealsConstants.GetAllReOrgAppealTerritories.SUCCESS:
			return {
				...state,
				reOrgAppealTerritoriesList : action.payload.result,
				reOrgAppealTerritoriesListLoading : false
			}
		case appealsConstants.GetAllReOrgAppealTerritories.CLEAR:
			return {
				...state,
				reOrgAppealTerritoriesList : null
			}
		case appealsConstants.DownloadAdditionalOrderMessageFiles.REQUEST:
			return {
				...state,
				isDownloadingAOMFile : true
			}
		case appealsConstants.DownloadAdditionalOrderMessageFiles.FAILURE:
			return {
				...state,
				error         : action.payload,
				isDownloadingAOMFile : false
			}
		case appealsConstants.DownloadAdditionalOrderMessageFiles.SUCCESS:
			return {
				...state,
				isDownloadingAOMFile : false
			}
		case appealsConstants.DownloadAdditionalOrderMessageFiles.CLEAR:
			return {
				...state,
				isDownloadingAOMFile : null
			}	
		case appealsConstants.DownloadAdditionalOrderMessageFile.REQUEST:
			return {
				...state,
				isDownloadingAOMFile : true
			}
		case appealsConstants.DownloadAdditionalOrderMessageFile.FAILURE:
			return {
				...state,
				error         : action.payload,
				isDownloadingAOMFile : false
			}
		case appealsConstants.DownloadAdditionalOrderMessageFile.SUCCESS:
			return {
				...state,
				isDownloadingAOMFile : false
			}
		case appealsConstants.DownloadAdditionalOrderMessageFile.CLEAR:
			return {
				...state,
				isDownloadingAOMFile : null
			}	
		case appealsConstants.ClearFileUploadChange:
			return{
				...state,
				fileUploadChange : null
			}
		case appealsConstants.EditOrgAppealNotice.REQUEST:
			return {
				...state,
				appealNoticeChanged : false
			}
		case appealsConstants.EditOrgAppealNotice.FAILURE:
			return {
				...state,
				error : action.payload,
			}
		case appealsConstants.EditOrgAppealNotice.SUCCESS:
			return {
				...state,
				appealNoticeChanged : true
			}
		case appealsConstants.EditOrgAppealNotice.CLEAR:
			return {
				...state,
				appealNoticeChanged : null
			}
		case appealsConstants.GenerateTransmittalLetterFile.REQUEST:
			return {
				...state,
				generateTransmittalLetterFileLoading : true
			}
		case appealsConstants.GenerateTransmittalLetterFile.FAILURE:
			return {
				...state,
				error : action.payload,
				generateTransmittalLetterFileLoading : false
			}
		case appealsConstants.GenerateTransmittalLetterFile.SUCCESS:
			return {
				...state,
				generateTransmittalLetterFileLoading : false
			}
		case appealsConstants.GenerateTransmittalLetterFile.CLEAR:
			return {
				...state,
				generateTransmittalLetterFileLoading : false
			}
		default:
			return state
	}
}
