export const appealsCollectiveConstants = {

  GetCollectiveAppeals: {
    REQUEST: 'GET_COLLECTIVE_APPEALS_REQUEST',
    SUCCESS: 'GET_COLLECTIVE_APPEALS_SUCCESS',
    FAILURE: 'GET_COLLECTIVE_APPEALS_FAILURE',
    CLEAR: 'GET_COLLECTIVE_APPEALS_CLEAR',
  },

  GetAppealUsers: {
    REQUEST: 'GET_COLLECTIVE_APPEAL_USERS_REQUEST',
    SUCCESS: 'GET_COLLECTIVE_APPEAL_USERS_SUCCESS',
    FAILURE: 'GET_COLLECTIVE_APPEAL_USERS_FAILURE',
    CLEAR: 'GET_COLLECTIVE_APPEAL_USERS_CLEAR',
  },

  GetOrgAppealUsers: {
    REQUEST: 'GET_ORG_COLLECTIVE_APPEAL_USERS_REQUEST',
    SUCCESS: 'GET_ORG_COLLECTIVE_APPEAL_USERS_SUCCESS',
    FAILURE: 'GET_ORG_COLLECTIVE_APPEAL_USERS_FAILURE',
    CLEAR: 'GET_ORG_COLLECTIVE_APPEAL_USERS_CLEAR',
  },

  GetOrgCollectiveAppealUserList: {
    REQUEST: 'GET_ORG_COLLECTIVE_APPEAL_USER_LIST_REQUEST',
    SUCCESS: 'GET_ORG_COLLECTIVE_APPEAL_USER_LIST_SUCCESS',
    FAILURE: 'GET_ORG_COLLECTIVE_APPEAL_USER_LIST_FAILURE',
    CLEAR: 'GET_ORG_COLLECTIVE_APPEAL_USER_LIST_CLEAR',
  },

  JoinToCollectiveAppeal: {
    REQUEST: 'JOIN_TO_COLLECTIVE_APPEAL_REQUEST',
    SUCCESS: 'JOIN_TO_COLLECTIVE_APPEAL_SUCCESS',
    FAILURE: 'JOIN_TO_COLLECTIVE_APPEAL_FAILURE',
    CLEAR: 'JOIN_TO_COLLECTIVE_APPEAL_CLEAR',
  },

  DeclineInvitation: {
    REQUEST: 'DECLINE_INVITATION_REQUEST',
    SUCCESS: 'DECLINE_INVITATION_SUCCESS',
    FAILURE: 'DECLINE_INVITATION_FAILURE',
    CLEAR: 'DECLINE_INVITATION_CLEAR',
  },

  InviteUserToAppeal: {
    REQUEST: 'INVITE_USER_TO_APPEAL_REQUEST',
    SUCCESS: 'INVITE_USER_TO_APPEAL_SUCCESS',
    FAILURE: 'INVITE_USER_TO_APPEAL_FAILURE',
    CLEAR: 'INVITE_USER_TO_APPEAL_CLEAR',
  },

  SubmitAppeal:{
    REQUEST: 'SUBMIT_APPEAL_REQUEST',
    SUCCESS: 'SUBMIT_APPEAL_SUCCESS',
    FAILURE: 'SUBMIT_APPEAL_FAILURE',
    CLEAR: 'SUBMIT_APPEAL_CLEAR',
  },

  CheckUserExistInAppeal: {
    REQUEST: 'CHECK_USER_EXIST_IN_APPEAL_REQUEST',
    SUCCESS: 'CHECK_USER_EXIST_IN_APPEAL_SUCCESS',
    FAILURE: 'CHECK_USER_EXIST_IN_APPEAL_FAILURE',
    CLEAR: 'CHECK_USER_EXIST_IN_APPEAL_CLEAR',
  },

  GetInvitedUsers: {
    REQUEST: 'GET_INVITED_USERS_REQUEST',
    SUCCESS: 'GET_INVITED_USERS_SUCCESS',
    FAILURE: 'GET_INVITED_USERS_FAILURE',
    CLEAR: 'GET_INVITED_USERS_CLEAR',
  },

  CheckUserHaveInvite: {
    REQUEST: 'CHECK_USER_HAVE_INVITE_REQUEST',
    SUCCESS: 'CHECK_USER_HAVE_INVITE_SUCCESS',
    FAILURE: 'CHECK_USER_HAVE_INVITE_FAILURE',
    CLEAR: 'CHECK_USER_HAVE_INVITE_CLEAR',
  },
  GetPendnigAppealData:{
    REQUEST: 'GET_PENDNIG_APPEAL_DATA_REQUEST',
    SUCCESS: 'GET_PENDNIG_APPEAL_DATA_SUCCESS',
    FAILURE: 'GET_PENDNIG_APPEAL_DATA_FAILURE',
    CLEAR: 'GET_PENDNIG_APPEAL_DATA_CLEAR',
  }
}
