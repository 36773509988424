import { constrolConstants } from "./constants";

const initialState = {
  messages: null,
  isLoading: null,
  controlStatuses: null,
  changed: null,
  messageFile: null,
	isFileLoading: false,
  responseChanged: null,
  approveChanged : null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case constrolConstants.getMessagesWithResponses.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case constrolConstants.getMessagesWithResponses.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        messages: action.payload.result,
      };
    }
    case constrolConstants.getMessagesWithResponses.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case constrolConstants.getAllControlStatuses.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case constrolConstants.getAllControlStatuses.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        controlStatuses: action.payload.result,
      };
    }
    case constrolConstants.getAllControlStatuses.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case constrolConstants.createOrderMessageResponse.REQUEST: {
      return {
        ...state,
        isLoading: true,
        responseChanged: false
      };
    }
    case constrolConstants.createOrderMessageResponse.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        responseChanged: action.payload.result,
      };
    }
    case constrolConstants.createOrderMessageResponse.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case constrolConstants.approveAdditionalOrderMessage.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case constrolConstants.approveAdditionalOrderMessage.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        approveChanged: action.payload.result,
      };
    }
    case constrolConstants.approveAdditionalOrderMessage.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case constrolConstants.getAdditionalOrderMessageFile.REQUEST: {
      return {
        ...state,
        isFileLoading: true,
      };
    }
    case constrolConstants.getAdditionalOrderMessageFile.SUCCESS: {
      return {
        ...state,
        isFileLoading: false,
      };
    }
    case constrolConstants.getAdditionalOrderMessageFile.FAILURE: {
      return {
        ...state,
        isFileLoading: false,
      };
    }
    case constrolConstants.clearApproveChanged: {
      return {
        ...state,
        approveChanged: null,
        responseChanged: null
      };
    }
    default:
      return state;
  }
}
