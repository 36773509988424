import { reContactConstants } from "./constans";
import api from './api';
import { defAction } from "../../helpers/defaultAction";


export const reContactAction = {
  getContact,
  setContact
};


function getContact() {
  const dispatchObj = {
    constants: reContactConstants.GetContact,
    service: {
      func: api.getContact,
      params: {}
    },
  };



  return defAction(dispatchObj);
}

function setContact(params) {

  const dispatchObj = {
    constants: reContactConstants.SendContact,
    service: {
      func: api.setContact,
      params: params,
    },
  };
  return defAction(dispatchObj);
}




