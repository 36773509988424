import {
	resolutionsConstants
}from './constants'

const initialState = {
	fileUploadChange                  : null,
	filesInfo                         : null,
	isLoadingDownload                 : false,
	resolutionDownloadFile            : null,
	error                             : null,
	filters                           : null,
	createResult                      : null,
	isSending                         : false,
	isApproveSending                  : false,
	reOrgAppeal                       : null,
	countRows                         : 0,
	listExecutors0                    : [],
	listExecutors1                    : [],
	listExecutors2                    : [],
	listExecutors3                    : [],
	listResolutions                   : [],
	executorId                        : null,
	resolution                        : null,
	resolutionChangeHistory           : [],
	updateExecutionResultSuccess      : null,
	updateApprovalSuccess             : null,
	updateResolutionSuccess           : null,
	approveFinalResultSuccess         : null,
	approveFinalResultSending         : false,
	resolutionFiles                   : null,
	deleteResolutionResult            : null,
	deleteResolutionSending           : false,
	declineResolutionResult           : null,
	declineResolutionSending          : false,
	isLoadingFiles                    : false,
	assignFinalResultSignerSuccess    : null,
	assignFinalResultSignerSending    : false,
	finaliseResolutionSuccess         : null,
	finaliseResolutionSending         : false,
	getResolutionChangeHistorySending : false,
	isAdded                           : false,
	executorFiles                     : [],
	addIntermediateResultSending      : false,
	addIntermediateResultSuccess      : null,
	sendToRevision                    : false,
	signingIntermediateResult		  : false
}

export default function (state = initialState, action){
	switch (action.type){
		case resolutionsConstants.GetResolutions.REQUEST:
			return {
				...state,
				isSending       : true,
				listResolutions : [],
				filters         : action.payload
			}
		case resolutionsConstants.GetResolutions.SUCCESS:
			return {
				...state,
				isSending       : false,
				listResolutions : action.payload.result.items.reduce((accum, next) => {
					return [
						...accum,
						{
							...next,
							key        : next.id,
							issuerName : next.issuer.fullName,
							dateDue    : {
								dateDueEnd : next.dateDueEnd,
								dateEnd    : next.dateEnd
							}
						}
					]
				}, []),
				countRows: action.payload.result.count
			}
		case resolutionsConstants.GetResolutions.FAILURE:
			return {
				...state,
				isSending       : false,
				error           : action.payload,
				listResolutions : []
			}
		case resolutionsConstants.GetExecutors.REQUEST:
		{
			return {
				...state,
				isSending                                     : true,
				[`listExecutors${ action.payload.idSelect }`] : [],
				executorId                                    : null
			}
		}

		case resolutionsConstants.GetExecutors.SUCCESS:
			return {
				...state,
				isSending                                               : false,
				[`listExecutors${ action.payload.sucParams.idSelect }`] : action.payload.result.items

				// executorId: action.payload.result.executorId
			}
		case resolutionsConstants.GetExecutors.FAILURE:
			return {
				...state,
				isSending                                                : false,
				error                                                    : action.payload,
				[`listExecutors${ action.payload.failParams.idSelect }`] : []
			}

		case resolutionsConstants.GetResolutionFilesInfo.REQUEST:
		case resolutionsConstants.GetResolutionIntermediateFileInfo.REQUEST:{
			return {
				...state,
				fileUploadChange : false,
				filesInfo        : null
			}
		}

		case resolutionsConstants.GetResolutionFilesInfo.SUCCESS:
		case resolutionsConstants.GetResolutionIntermediateFileInfo.SUCCESS:{
			return {
				...state,
				fileUploadChange : true,
				filesInfo        : action.payload.result
			}
		}

		case resolutionsConstants.GetResolutionFilesInfo.FAILURE:
		case resolutionsConstants.GetResolutionIntermediateFileInfo.FAILURE:{
			return {
				...state,
				fileUploadChange : false,
				filesInfo        : null
			}
		}

		case resolutionsConstants.GetResolutionIntermediateFileInfo.CLEAR:
		case resolutionsConstants.GetResolutionFilesInfo.CLEAR:{
			return {
				...state,
				fileUploadChange : false,
				filesInfo        : null
			}
		}

		case resolutionsConstants.ChangeExecutor.REQUEST:
			return {
				...state,
				isSending                                     : true,
				[`listExecutors${ action.payload.idSelect }`] : [],
				executorId                                    : null,
				createResult                                  : false

			}
		case resolutionsConstants.ChangeExecutor.SUCCESS:
			return {
				...state,
				isSending                                               : false,
				createResult                                            : true,
				[`listExecutors${ action.payload.sucParams.idSelect }`] : action.payload.result.items

				// executorId: action.payload.result.executorId
			}
		case resolutionsConstants.ChangeExecutor.FAILURE:
			return {
				...state,
				isSending                                                : false,
				error                                                    : action.payload,
				[`listExecutors${ action.payload.failParams.idSelect }`] : [],
				executorId                                               : null,
				createResult                                             : false

			}
		case resolutionsConstants.ApproveResolution.REQUEST:
			return {
				...state,
				isApproveSending : true,
				createResult     : null
			}
		case resolutionsConstants.ApproveResolution.SUCCESS:
			return {
				...state,
				isApproveSending : false,
				createResult     : action.payload,
				resolution       : {
					...state.resolution,
					dateEnd        : action.payload.result.dateEnd,
					answerApproved : action.payload.result.answerApproved
				},
				listResolutions: state.listResolutions.map((item, index) => {
					if (item.id !== action.payload.result.id)
						return item

					return {
						...item,
						dateEnd : action.payload.result.dateEnd,
						dateDue : {
							...item.dateDue,
							dateEnd: action.payload.result.dateEnd
						},
						answerApproved: action.payload.result.answerApproved
					}
				})
			}
		case resolutionsConstants.ApproveResolution.FAILURE:
			return {
				...state,
				isApproveSending : false,
				error            : action.payload
			}
		case resolutionsConstants.GetResolutionFilesById.REQUEST:
			return {
				...state,
				isLoadingFiles  : true,
				resolutionFiles : null
			}
		case resolutionsConstants.GetResolutionFilesById.SUCCESS:
			return {
				...state,
				isLoadingFiles  : false,
				resolutionFiles : action.payload.result
			}
		case resolutionsConstants.GetResolutionFilesById.FAILURE:
			return {
				...state,
				isLoadingFiles : false,
				error          : action.payload
			}
		case resolutionsConstants.DownloadResolutionFile.REQUEST:
			return {
				...state,
				isLoadingDownload      : true,
				resolutionDownloadFile : null
			}
		case resolutionsConstants.DownloadResolutionFile.SUCCESS:
			return {
				...state,
				isLoadingDownload      : false,
				resolutionDownloadFile : action.payload.result
			}
		case resolutionsConstants.DownloadResolutionFile.FAILURE:
			return {
				...state,
				error             : action.payload,
				isLoadingDownload : false
			}
		case resolutionsConstants.GetResolutionFilesById.CLEAR:
			return {
				...state,
				isLoadingDownload      : false,
				resolutionDownloadFile : null,
				resolutionFiles        : null
			}
		case resolutionsConstants.CLEAR:
			return {
				...state,
				createResult: null
			}
		case resolutionsConstants.CreateResolution.REQUEST:
			return {
				...state,
				isSending    : true,
				createResult : null
			}
		case resolutionsConstants.CreateResolution.SUCCESS:
		{
			return {
				...state,
				isSending    : false,
				createResult : action.payload
			}
		}

		case resolutionsConstants.CreateResolution.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case resolutionsConstants.SignResolution.REQUEST:
			return {
				...state,
				isSending    : true,
				createResult : null
			}
		case resolutionsConstants.SignResolution.SUCCESS:
		{
			return {
				...state,
				isSending    : false,
				createResult : action.payload
			}
		}

		case resolutionsConstants.SignResolution.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}

		case resolutionsConstants.SaveResolution.REQUEST:
			return {
				...state,
				isSending    : true,
				createResult : null
			}
		case resolutionsConstants.SaveResolution.SUCCESS:
		{
			return {
				...state,
				isSending    : false,
				createResult : action.payload
			}
		}

		case resolutionsConstants.SaveResolution.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case resolutionsConstants.GetResolution.REQUEST:
			return {
				...state,
				isSending  : true,
				resolution : null

			}
		case resolutionsConstants.GetResolution.SUCCESS:
			return {
				...state,
				isSending  : false,
				resolution : action.payload.result
			}
		case resolutionsConstants.GetResolution.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case resolutionsConstants.UpdateResolution.REQUEST:
			return {
				...state,
				isSending               : true,
				updateResolutionSuccess : null
			}
		case resolutionsConstants.UpdateResolution.SUCCESS:
			return {
				...state,
				isSending               : false,
				resolution              : action.payload.result,
				updateResolutionSuccess : true
			}
		case resolutionsConstants.UpdateResolution.FAILURE:
			return {
				...state,
				isSending               : false,
				error                   : action.payload,
				updateResolutionSuccess : false
			}
		case resolutionsConstants.UpdateApproval.REQUEST:
			return {
				...state,
				isSending             : true,
				updateApprovalSuccess : null
			}
		case resolutionsConstants.UpdateApproval.SUCCESS:
			const executors = state.resolution.executors.map(x => {
				return {
					...x
				}
			})
			const j = executors.indexOf(executors.find(x => x.resolutionExecutorId === action.payload.result.resolutionExecutorId))
			executors[j] = {
				...action.payload.result,
				files : executors[j].files
			}
			return {
				...state,
				isSending  : false,
				resolution : {
					...state.resolution,
					executors : executors,
					canFinish : action.payload.result.canFinish
				},
				updateApprovalSuccess: true
			}
		case resolutionsConstants.UpdateApproval.FAILURE:
			return {
				...state,
				isSending             : false,
				error                 : action.payload,
				updateApprovalSuccess : false
			}
		case resolutionsConstants.UpdateExecutionResult.REQUEST:
			return {
				...state,
				isSending                    : true,
				updateExecutionResultSuccess : null
			}
		case resolutionsConstants.UpdateExecutionResult.SUCCESS:
			const resolutionExecutors = state.resolution.executors.map(x => {
				return {
					...x
				}
			})
			const i = resolutionExecutors.indexOf(resolutionExecutors.find(x => x.resolutionExecutorId === action.payload.result.resolutionExecutorId))
			resolutionExecutors[i] = action.payload.result

			return {
				...state,
				isSending  : false,
				resolution : {
					...state.resolution,
					executors: resolutionExecutors
				},
				updateExecutionResultSuccess: true
			}
		case resolutionsConstants.UpdateExecutionResult.FAILURE:
			return {
				...state,
				isSending                    : false,
				error                        : action.payload,
				updateExecutionResultSuccess : false
			}

		case resolutionsConstants.DeleteResolution.REQUEST:
			return {
				...state,
				deleteResolutionResult  : null,
				deleteResolutionSending : true
			}
		case resolutionsConstants.DeleteResolution.SUCCESS:
			return {
				...state,
				deleteResolutionSending : false,
				deleteResolutionResult  : action.payload.result

				// executorId: action.payload.result.executorId
			}
		case resolutionsConstants.DeleteResolution.FAILURE:
			return {
				...state,
				deleteResolutionResult  : null,
				deleteResolutionSending : false
			}
		case resolutionsConstants.DeleteResolution.CLEAR:
			return {
				...state,
				deleteResolutionResult  : null,
				deleteResolutionSending : false
			}
		case resolutionsConstants.DeclineResolution.REQUEST:
			return {
				...state,
				declineResolutionResult: null
			}
		case resolutionsConstants.DeclineResolution.SUCCESS:
			return {
				...state,
				declineResolutionResult: action.payload.result
			}
		case resolutionsConstants.DeclineResolution.FAILURE:
			return {
				...state,
				declineResolutionResult : null,
				error                   : action.payload
			}
		case resolutionsConstants.ApproveFinalResult.REQUEST:
			return {
				...state,
				approveFinalResultSuccess : null,
				approveFinalResultSending : true
			}
		case resolutionsConstants.ApproveFinalResult.SUCCESS:
			return {
				...state,
				approveFinalResultSending : false,
				approveFinalResultSuccess : true,
				resolution                : action.payload.result
			}
		case resolutionsConstants.ApproveFinalResult.FAILURE:
			return {
				...state,
				approveFinalResultSending : false,
				approveFinalResultSuccess : false,
				error                     : action.payload
			}
		case resolutionsConstants.AssignFinalResultSigner.REQUEST:
			return {
				...state,
				assignFinalResultSignerSuccess : null,
				assignFinalResultSignerSending : true
			}
		case resolutionsConstants.AssignFinalResultSigner.SUCCESS:
			return {
				...state,
				assignFinalResultSignerSuccess : true,
				assignFinalResultSignerSending : false,
				resolution                     : action.payload.result
			}
		case resolutionsConstants.AssignFinalResultSigner.FAILURE:
			return {
				...state,
				assignFinalResultSignerSuccess : false,
				assignFinalResultSignerSending : false,
				error                          : action.payload
			}
		case resolutionsConstants.FinaliseResolution.REQUEST:
			return {
				...state,
				finaliseResolutionSuccess : null,
				finaliseResolutionSending : true
			}
		case resolutionsConstants.FinaliseResolution.SUCCESS:
			return {
				...state,
				finaliseResolutionSuccess : true,
				finaliseResolutionSending : false,
				resolution                : action.payload.result
			}
		case resolutionsConstants.FinaliseResolution.FAILURE:
			return {
				...state,
				finaliseResolutionSuccess : false,
				finaliseResolutionSending : false,
				error                     : action.payload
			}
		case resolutionsConstants.ResetAllBools:
			return {
				...state,
				updateExecutionResultSuccess   : null,
				updateApprovalSuccess          : null,
				updateResolutionSuccess        : null,
				approveFinalResultSuccess      : null,
				assignFinalResultSignerSuccess : null,
				finaliseResolutionSuccess      : null
			}
		case resolutionsConstants.GetResolutionChangeHistory.REQUEST:
			return {
				...state,
				getResolutionChangeHistorySending: true
			}
		case resolutionsConstants.GetResolutionChangeHistory.SUCCESS:
			return {
				...state,
				getResolutionChangeHistorySending : false,
				resolutionChangeHistory           : action.payload.result
			}
		case resolutionsConstants.GetResolutionChangeHistory.FAILURE:
			return {
				...state,
				getResolutionChangeHistorySending : false,
				error                             : action.payload,
				resolutionChangeHistory           : []
			}
		case resolutionsConstants.AddExecutorFiles.REQUEST:
			return {
				...state,
				isAdded: null
			}
		case resolutionsConstants.AddExecutorFiles.SUCCESS:
			return {
				...state,
				isAdded: action.payload.result
			}
		case resolutionsConstants.AddExecutorFiles.FAILURE:
			return {
				...state,
				isAdded : false,
				error   : action.payload
			}
		case resolutionsConstants.GetExecutorFiles.REQUEST:
			return {
				...state,
				executorFiles: null
			}
		case resolutionsConstants.GetExecutorFiles.SUCCESS:
			return {
				...state,
				resolution: {
					...state.resolution,
					executors: state.resolution.executors.map(item => {
						if (item.files.resolutionExecutorId === action.payload.result.resolutionExecutorId){
							return {
								...item,
								files: action.payload.result
							}
						}
						return {
							...item
						}
					})
				},
				executorFiles: action.payload.result
			}
		case resolutionsConstants.GetExecutorFiles.FAILURE:
			return {
				...state,
				executorFiles : null,
				error         : action.payload
			}
		case resolutionsConstants.AddResolutionFiles.REQUEST:
			return {
				...state,
				isFileAdded: null
			}
		case resolutionsConstants.AddResolutionFiles.SUCCESS:
			return {
				...state,
				isFileAdded: action.payload.result
			}
		case resolutionsConstants.AddResolutionFiles.FAILURE:
			return {
				...state,
				isFileAdded : false,
				error       : action.payload
			}

		case resolutionsConstants.AddIntermediateResult.REQUEST:
			return {
				...state,
				addIntermediateResultSending : true,
				addIntermediateResultSuccess : null
			}
		case resolutionsConstants.AddIntermediateResult.SUCCESS:
			return {
				...state,
				addIntermediateResultSending : false,
				addIntermediateResultSuccess : true,
				resolution                   : {
					...state.resolution,
					resolutionIntermediateResults: action.payload.result
				}
			}
		case resolutionsConstants.AddIntermediateResult.FAILURE:
			return {
				...state,
				addIntermediateResultSending : false,
				addIntermediateResultSuccess : false,
				error                        : action.payload
			}
		case resolutionsConstants.AddIntermediateResult.CLEAR:
			return {
				...state,
				addIntermediateResultSending : false,
				addIntermediateResultSuccess : null,
				error                        : action.payload
			}
		case resolutionsConstants.SignIntermediateResult.REQUEST:
			return {
				...state,
				signingIntermediateResult : true
			}
		case resolutionsConstants.SignIntermediateResult.SUCCESS:
			return {
				...state,
				resolution: {
					...state.resolution,
					resolutionIntermediateResults: action.payload.result
				},
				signingIntermediateResult : false
			}
		case resolutionsConstants.SignIntermediateResult.FAILURE:
			return {
				...state,
				error: action.payload,
				signingIntermediateResult : false
			}
		case resolutionsConstants.SendToRevision.REQUEST:
			return {
				...state,
				sendToRevision: false
			}
		case resolutionsConstants.SendToRevision.SUCCESS:
			return {
				...state,
				sendToRevision            : true,
				resolutionRevisionReasons : action.payload.result
			}
		case resolutionsConstants.SendToRevision.FAILURE:
			return {
				...state,
				sendToRevision : false,
				error          : action.payload
			}
		case resolutionsConstants.SendToRevision.CLEAR:
			return {
				...state,
				sendToRevision: false
			}

		default:
			return state
	}
}
