export const appealUserConstants = {

  GetUsersByReOrgId: {
    REQUEST: "GET_USERS_BY_REORG_ID_REQUEST",
    SUCCESS: "GET_USERS_BY_REORG_ID_SUCCESS",
    FAILURE: "GET_USERS_BY_REORG_ID_FAILURE",
  },

  GetReOrgInfoByUser: {
    REQUEST: "GET_REORG_INFO_BY_USER_REQUEST",
    SUCCESS: "GET_REORG_INFO_BY_USER_SUCCESS",
    FAILURE: "GET_REORG_INFO_BY_USER_FAILURE",
  },

  GetUserExecutPosition: {
    REQUEST: "GET_USER_EXECUT_POSITION_REQUEST",
    SUCCESS: "GET_USER_EXECUT_POSITION_SUCCESS",
    FAILURE: "GET_USER_EXECUT_POSITION_FAILURE",
  },

  GetAppealUserAddress: {
    REQUEST: "GET_APPEAL_USER_ADDRESS_REQUEST",
    SUCCESS: "GET_APPEAL_USER_ADDRESS_SUCCESS",
    FAILURE: "GET_APPEAL_USER_ADDRESS_FAILURE",
    CLEAR: "GET_APPEAL_USER_ADDRESS_CLEAR",
  },
  GetReOrgInfoByReOrgId: {
    REQUEST: "GET_REORG_INFO_BY_REORG_ID_REQUEST",
    SUCCESS: "GET_REORG_INFO_BY_REORG_ID_SUCCESS",
    FAILURE: "GET_REORG_INFO_BY_REORG_ID_FAILURE",
    CLEAR: "GET_REORG_INFO_BY_REORG_ID_CLEAR",
  }
}