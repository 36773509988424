const i18n = {
  title: {
    1: "Ошибка аутентификации",
    0: "Памылка аўтэнтыфікацыі",
  },
  content: {
    1: "Время сессии истекло. Пожалуйста, повторите вход в личный кабинет.",
    0: "Час сесіі скончыўся. Калі ласка, паўтарыце ўваход у асабісты кабінет.",
  }
};

export default i18n;

