export const controlSubsystemConstants = {
	getOrgsByNameOrUnpPaged: {
		FAILURE : 'GET_ORGS_BY_NAME_OR_UNP_PAGED_FAILURE',
		REQUEST : 'GET_ORGS_BY_NAME_OR_UNP_PAGED_REQUEST',
		SUCCESS : 'GET_ORGS_BY_NAME_OR_UNP_PAGED_SUCCESS'
	},
	getReceivedRequestsPaged: {
		FAILURE : 'GET_RECEIVED_REQUESTS_PAGED_FAILURE',
		REQUEST : 'GET_RECEIVED_REQUESTS_PAGED_REQUEST',
		SUCCESS : 'GET_RECEIVED_REQUESTS_PAGED_SUCCESS'
	},
	getSentRequestsPaged: {
		FAILURE : 'GET_SENT_REQUESTS_PAGED_FAILURE',
		REQUEST : 'GET_SENT_REQUESTS_PAGED_REQUEST',
		SUCCESS : 'GET_SENT_REQUESTS_PAGED_SUCCESS'
	},
	createRequest: {
		FAILURE : 'CREATE_ACCESS_REQUEST_FAILURE',
		REQUEST : 'CREATE_ACCESS_REQUEST_REQUEST',
		SUCCESS : 'CREATE_ACCESS_REQUEST_SUCCESS'
	},
	createResponse: {
		FAILURE : 'CREATE_ACCESS_RESPONSE_FAILURE',
		REQUEST : 'CREATE_ACCESS_RESPONSE_REQUEST',
		SUCCESS : 'CREATE_ACCESS_RESPONSE_SUCCESS'
	},
	getAppealList: {
		FAILURE : 'GET_APPEAL_LIST_FAILURE',
		REQUEST : 'GET_APPEAL_LIST_REQUEST',
		SUCCESS : 'GET_APPEAL_LIST_SUCCESS'
	},
	getReOrgAppealInfo: {
		FAILURE : 'GET_ORG_APPEAL_INFO_FAILURE',
		REQUEST : 'GET_ORG_APPEAL_INFO_REQUEST',
		SUCCESS : 'GET_ORG_APPEAL_INFO_SUCCESS'
	},
	getReOrgAppealResolutionsPaged: {
		FAILURE : 'GET_ORG_APPEAL_RESOLUTIONS_PAGED_FAILURE',
		REQUEST : 'GET_ORG_APPEAL_RESOLUTIONS_PAGED_REQUEST',
		SUCCESS : 'GET_ORG_APPEAL_RESOLUTIONS_PAGED_SUCCESS'
	},
	getAppealInfoByOrgPaged: {
		FAILURE : 'GET_ORG_APPEAL_INFO_PAGED_FAILURE',
		REQUEST : 'GET_ORG_APPEAL_INFO_PAGED_REQUEST',
		SUCCESS : 'GET_ORG_APPEAL_INFO_PAGED_SUCCESS'
	},
	getAccessRequestById: {
		FAILURE : 'GET_ACCESS_REQUEST_FAILURE',
		REQUEST : 'GET_ACCESS_REQUEST_REQUEST',
		SUCCESS : 'GET_ACCESS_REQUEST_SUCCESS'
	},
	getReceivedRequest: {
		FAILURE : 'GET_RECIEVED_REQUEST_FAILURE',
		REQUEST : 'GET_RECIEVED_REQUEST_REQUEST',
		SUCCESS : 'GET_RECIEVED_REQUEST_SUCCESS'
	},
	getSentRequest: {
		FAILURE : 'GET_SENT_REQUEST_FAILURE',
		REQUEST : 'GET_SENT_REQUEST_REQUEST',
		SUCCESS : 'GET_SENT_REQUEST_SUCCESS'
	},
	getResponseAccess: {
		FAILURE : 'GET_RESPONSE_ACCESS_FAILURE',
		REQUEST : 'GET_RESPONSE_ACCESS_REQUEST',
		SUCCESS : 'GET_RESPONSE_ACCESS_SUCCESS'
	},
	getOrgName: {
		FAILURE : 'GET_ORG_NAME_FAILURE',
		REQUEST : 'GET_ORG_NAME_REQUEST',
		SUCCESS : 'GET_ORG_NAME_SUCCESS'
	},
	DownloadSubsystemFile: {
		REQUEST : "DOWNLOAD_SUBSYSTEM_FILE_BY_ID_REQUEST",
		SUCCESS : "DOWNLOAD_SUBSYSTEM_FILE_BY_ID_SUCCESS",
		FAILURE : "DOWNLOAD_SUBSYSTEM_FILE_BY_ID_FAILURE"
	},
	clearSubsystem : 'CLEAR_SUBSYSTEM',
	setOrgName     : 'SET_ORG_NAME'
}

