export const questionConstants = {
  GetAllQuestions: {
    REQUEST: "GET_QUESTION_REQUEST",
    SUCCESS: "GET_QUESTION_SUCCESS",
    FAILURE: "GET_QUESTION_FAILURE",
    CLEAR: "GET_QUESTION_CLEAR",
  },
 GetHelpsForMain: {
    REQUEST: "GET_QUESTION_REQUEST",
    SUCCESS: "GET_QUESTION_SUCCESS",
    FAILURE: "GET_QUESTION_FAILURE",
    CLEAR: "GET_QUESTION_CLEAR",
  }
};
