import {
	applicantNotificationsConstants
}from "./constants"
import {
	defAction
}from "../../../helpers/defaultAction"
import api from "./api"

export const applicantNotificationsActions = {
	clearNotifications,
	downloadNotificationFile,
	getCountNotRead,
	getFirstNotifications,
	getPagedNotifications,
	readAllNotifications
}

function clearNotifications(){
	return (dispatch) => dispatch({
		type: applicantNotificationsConstants.getPagedNotifications.CLEAR
	})
}

function getFirstNotifications(values){
	const dispatchObj = {
		constants : applicantNotificationsConstants.getFirstNotifications,
		service   : {
			func   : api.getFirstNotifications,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getCountNotRead(){
	const dispatchObj = {
		constants : applicantNotificationsConstants.getCountNotRead,
		service   : {
			func: api.getCountNotRead
		}
	}
	return defAction(dispatchObj)
}

function getPagedNotifications(values){
	const dispatchObj = {
		constants : applicantNotificationsConstants.getPagedNotifications,
		service   : {
			func   : api.getPagedNotifications,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function downloadNotificationFile(values){
	const dispatchObj = {
		constants : applicantNotificationsConstants.downloadNotificationFile,
		service   : {
			func   : api.downloadNotificationFile,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function readAllNotifications(){
	const dispatchObj = {
		constants : applicantNotificationsConstants.readAllNotifications,
		service   : {
			func   : api.readAllNotifications
		}
	}
	return defAction(dispatchObj)
}